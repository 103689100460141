<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('routes.deliveryManagement') }}</h1>
    </template>
    <h3 class="mb-4">
      {{ $t('delivery.receivingReturningDocumentsOverview') }}
    </h3>
    <div>
      <Table
        v-loading="loading"
        border
        rounded
        :data="tableData"
        :columns="columns"
        show-index
        @row-click="handleRowClick"
      >
        <template #cell-deliveredOn="{ rowData: { deliveredOn } }">
          {{ dateFormatter(deliveredOn) }}
        </template>
      </Table>
    </div>
  </PageLayout>
</template>
<script>
import { computed, ref } from 'vue';

import { options } from '@/locale/dateConfig';
import { PageLayout, Table } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useDeliveryTasks } from './compositions/useDeliveryTasks';

const TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  DOCUMENT: 'document',
  DELIVERED_ON: 'deliveredOn',
  ITEM_COUNT: 'itemCount',
  TOTAL_AMOUNT: 'totalAmount',
};

export default {
  components: { PageLayout, Table },
  setup() {
    const { currentTenant } = useTenancy();
    const { formatCentsToCurrency } = useCurrency({ maximumFractionDigits: 2 });
    const { tasks, loading } = useDeliveryTasks(computed(() => ({ businessId: currentTenant.value.id })));
    return {
      formatCentsToCurrency,
      tasks,
      loading,
      activeTab: ref(0),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          width: '25%',
        },
        {
          header: this.$t('commons.document'),
          key: TABLE_HEADERS.DOCUMENT,
          width: '18%',
        },
        {
          header: this.$t('delivery.headers.deliveredOn'),
          key: TABLE_HEADERS.DELIVERED_ON,
          width: '18%',
        },
        {
          header: this.$t('delivery.headers.itemCount'),
          key: TABLE_HEADERS.ITEM_COUNT,
          width: '18%',
        },
        {
          header: this.$t('delivery.headers.totalAmountInclVAT'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
          width: '18%',
        },
      ];
    },
    tableData() {
      return this.tasks.map((task) => {
        return {
          [TABLE_HEADERS.SUPPLIER]: task.delivery.document.supplier.name,
          [TABLE_HEADERS.DOCUMENT]: `${this.$i18n.t(
            `document.exports.schema.type.shortName.${task.delivery.document.type}`
          )} ${task.delivery.document.documentNumber}`,
          [TABLE_HEADERS.DELIVERED_ON]: task.delivery.document.issueDate,
          [TABLE_HEADERS.ITEM_COUNT]: task.delivery.document.items?.length,
          [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(task.delivery.document.totalAmount * 100) ?? '-',
          documentId: task.delivery.document.id,
          supplierId: task.delivery.document.supplier.id,
        };
      });
    },
  },
  methods: {
    dateFormatter(ms) {
      return new Date(ms).toLocaleDateString(this.$i18n.locale, options.short);
    },
    formatMoney(value) {
      return this.formatCentsToCurrency(value) ?? '-';
    },
    handleRowClick(rowIndex) {
      this.$router.push({ name: 'goodsExchangeRecording', query: { taskNumber: rowIndex + 1 } });
    },
  },
};
</script>
